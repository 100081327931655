import { SerializedStyles } from '@emotion/utils'
import { color } from 'driverama-core/styles/variables'
import {
  SButton,
  SContainer,
  SIcon,
  SItem,
  SItemBackground,
  SText
} from './ToggleText.styled'

interface ToggleTextOption<TValue extends string> {
  label: string
  value: TValue
  icon?: React.FC<React.SVGProps<SVGSVGElement>>
}

interface Props<TValue extends string> {
  options: ToggleTextOption<TValue>[]
  value?: TValue
  onChange?: (value: TValue) => void
  disabled?: boolean
  classes?: {
    container?: SerializedStyles
  }
}

export function ToggleText<TValue extends string>(props: Props<TValue>) {
  return (
    <SContainer css={props.classes?.container}>
      {props.options.map(option => {
        const isActive = props.value === option.value

        return (
          <SItem key={option.value}>
            {isActive && <SItemBackground />}

            <SButton
              type="button"
              onClick={e => {
                e.stopPropagation()
                props.onChange?.(option.value)
              }}
              // NOTE: it seems that this needs to be here to avoid problems with z-index
              whileTap={isActive ? {} : { opacity: 0.6 }}
              disabled={props.disabled}
            >
              {option.icon && (
                <SIcon
                  as={option.icon}
                  css={isActive && { color: color('black') }}
                />
              )}

              <SText
                variant="body"
                size="small"
                css={isActive && { color: color('black') }}
              >
                {option.label}
              </SText>
            </SButton>
          </SItem>
        )
      })}
    </SContainer>
  )
}
