import styled from '@emotion/styled'
import { TextBodyMedium } from 'driverama-core/components/text/Text'
import { inset } from 'driverama-core/styles/inset'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import {
  color,
  radius,
  shadow,
  time,
  zIndex
} from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SContainer = styled.div`
  display: flex;
  align-items: stretch;

  color: ${color('night-text')};
  background-color: ${color('night-l-800')};
  border-radius: ${radius('corner-larger')};
  min-height: ${size(14)};
  padding: ${size(1)};

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
`

export const SItem = styled.div`
  position: relative;
  flex: 1;
`

export const SItemBackground = styled.div`
  position: absolute;
  ${inset(0)};

  background-color: ${color('white')};
  border-radius: ${radius('full')};
  box-shadow: ${shadow(2)};

  z-index: ${zIndex('order-1')};
`

export const SButton = styled(motion.button)`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: ${size(2)};
  padding-bottom: ${size(2)};

  color: ${color('night-text')};
  text-align: center;

  cursor: pointer;
  position: relative;
  z-index: ${zIndex('order-2')};

  @media ${media.lte('MD')} {
    padding: ${size(3)};
  }
`

export const SIcon = styled.svg`
  width: ${size(6)};
  height: ${size(6)};

  margin-right: ${size(1.5)};
  transition: color ${time('control')} ease-in-out;

  @media ${media.lte('MD')} {
    display: none;
  }
`

export const SText = styled(TextBodyMedium)`
  transition: color ${time('control')} ease-in-out;
  white-space: nowrap;
`
